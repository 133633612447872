import { SubscribeToMoreOptions, useApolloClient } from '@apollo/client'
import * as React from 'react'
import { config } from '../config'
import { auth } from '/fiweb/lib/auth'
import { client } from '/~/lib/issuer-client/client'
import {
  ContextUserUpdatedDocument,
  ContextUserUpdatedSubscription,
  ContextUserUpdatedSubscriptionVariables,
  MeAllQuery,
  MeAllQueryResult,
  useMeAllQuery,
} from '/~/types/graphql'
import { useMyEmissionProcessesInNewIssuerQuery } from '/~/types/issuer-graphql'
import { gtmEventLogout } from '../googleTagManager'

type SubToMoreOptions = SubscribeToMoreOptions<
  MeAllQuery,
  ContextUserUpdatedSubscriptionVariables,
  ContextUserUpdatedSubscription
>

export type AuthContextType = {
  user: MeAllQuery['me']
} & Pick<MeAllQueryResult, 'loading' | 'refetch' | 'error' | 'startPolling' | 'stopPolling'> & {
    hasEmissionsInNewSystem: boolean
    issuerRefetch: () => void
    logOut: () => Promise<void>
  }

export const UserAuthContext = React.createContext({} as AuthContextType)

const useUserQuerySubscription = (): AuthContextType => {
  const {
    data: currentData,
    previousData,
    loading,
    error,
    refetch,
    subscribeToMore,
    startPolling,
    stopPolling,
  } = useMeAllQuery()
  const apolloClient = useApolloClient()
  const {
    data: issuerData,
    loading: issuerLoading,
    refetch: issuerRefetch,
  } = useMyEmissionProcessesInNewIssuerQuery({
    client,
    skip: !client,
  })
  const data = currentData ?? previousData

  React.useEffect(() => {
    if (!loading && data?.me) {
      const unsub = subscribeToMore({
        document: ContextUserUpdatedDocument,
        variables: { _id: data?.me?._id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) {
            return prev
          }

          if (subscriptionData?.data?.userUpdated?.loginBlocked) {
            auth(config.authHost).logout()
            return { me: null }
          }
          return subscriptionData?.data?.userUpdated ? { me: subscriptionData?.data?.userUpdated } : prev
        },
      } as SubToMoreOptions)
      apolloClient.resetStore()
      client.resetStore()
      return () => unsub()
    }
  }, [loading, data?.me?._id])

  React.useEffect(() => {
    if (!loading && data) {
      try {
        const userInvokedLogout = sessionStorage.getItem(config.userInvokedLogoutKey)
        const existingValue = localStorage.getItem(config.loggedInKey)
        const newValue = `${!!data.me}`

        localStorage.setItem(config.loggedInKey, newValue)

        if (existingValue && existingValue !== newValue && !userInvokedLogout) {
          window.dispatchEvent(new StorageEvent('storage', { key: config.loggedInKey }))
        }

        sessionStorage.removeItem(config.userInvokedLogoutKey)
      } catch (_e) {
        // Silently fail if cookies are disabled (fixes embed crash problem)
      }
    }
  }, [data, loading])

  const logOut = async () => {
    gtmEventLogout()
    await auth(config.authHost).logout(true)

    if (apolloClient) {
      await apolloClient.resetStore()
    }
    if (client) {
      await client.resetStore()
    }
  }

  return {
    user: data?.me,
    hasEmissionsInNewSystem: !issuerLoading && issuerData?.myEmissionProcesses.length > 0,
    error,
    loading,
    logOut,
    refetch,
    issuerRefetch,
    startPolling,
    stopPolling,
  }
}

export const useAuthContext = () => {
  const ctx = React.useContext(UserAuthContext)
  return ctx
}

export const AuthContextProvider = ({ children }) => {
  const authContext = useUserQuerySubscription()
  return <UserAuthContext.Provider value={authContext}>{children}</UserAuthContext.Provider>
}
