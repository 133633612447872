import { Card, InfoSignIcon, Menu, Pane, PaneProps, Strong, Text, Tooltip } from 'evergreen-ui'
import * as React from 'react'
import { ProfileAddress as ProfileAddressType, ProfileAddressInput } from '/~/types/graphql'
import { useFormContext } from 'react-hook-form'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import { Caption, ControlledTextInput } from '/fiweb/components'

interface ValidFieldType {
  names: Array<string>
  key: string
  ignore?: boolean
  combineWith?: ValidFieldType['key']
}

export const validGoogleFields: ValidFieldType[] = [
  { names: ['street_number'], key: 'streetNumber', ignore: true },
  { names: ['route'], key: 'street', combineWith: 'streetNumber' },
  { names: ['postal_town', 'locality'], key: 'postalTown' },
  { names: ['country'], key: 'countryCode' },
  { names: ['postal_code'], key: 'postalCode' },
]

interface Props {
  name: string
  onAddressSelect?: (input?: ProfileAddressInput) => void
}

export const GoogleAddressLookup = ({ name, onAddressSelect, ...paneProps }: Props & PaneProps) => {
  const [firstRender, setFirstRender] = React.useState(true)
  const { control, watch } = useFormContext()

  const clickOutsideRef = React.useRef<HTMLDivElement>(null)
  const resultsWrapperRef = React.useRef<HTMLDivElement>(null)

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      // @ts-ignore TODO - @types/google.maps gives very odd results for other parts of the code - leaving this here for now
      location: window?.google?.maps?.LatLng?.(61.77, 9.54), // Otta - Approximately the middle between Oslo, Bergen and Trondheim
      radius: 50000,
    },
    debounce: 500,
  })

  const handleClickOutside = (e) => {
    if (!clickOutsideRef.current?.contains(e.target)) {
      clearSuggestions()
    }
  }

  React.useEffect(() => {
    if (clickOutsideRef?.current) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [clickOutsideRef?.current])

  const getFieldData = (fieldValue) => {
    let field = null
    fieldValue?.types?.forEach((val) => {
      const match = validGoogleFields?.find((vf) => vf.names.find((n) => n === val))
      if (match) {
        field = { [match.key]: fieldValue?.short_name }
      }
    })
    return field
  }

  const handleSelect = async (e, { place_id }) => {
    const addressFields = await getDetails({ placeId: place_id, fields: ['address_components'] })

    const newFields = addressFields?.address_components?.reduce(
      (acc, val) => ({
        ...acc,
        ...(getFieldData(val) || undefined),
      }),
      {},
    )

    const result: Omit<ProfileAddressType, '__typename'> = {}
    validGoogleFields.forEach((field) => {
      if (!field.ignore) {
        let fieldValue = newFields[field.key] || ''
        if (field.combineWith && newFields[field.combineWith]) {
          fieldValue = `${fieldValue} ${newFields[field.combineWith] || ''}`
        }
        result[field.key] = fieldValue
      }
    })

    setValue(result.street, false)
    onAddressSelect(result)

    clearSuggestions()
    e.preventDefault()
    const streetInputElement = document.getElementById('address-field-street-input')
    if (streetInputElement) {
      streetInputElement.focus()
    }
  }

  const watchedInput = watch(name)
  const hasSuggestions = status === 'OK' && data?.length > 0

  React.useEffect(() => {
    if (watchedInput !== value && watchedInput !== undefined) {
      if (watchedInput && firstRender) {
        // To avoid showing suggestion on first render
        setFirstRender(false)
        setValue(watchedInput, false)
      } else if (watchedInput?.length === 0) {
        // To avoid showing suggestions with empty string
        setValue(watchedInput, false)
        clearSuggestions()
      } else {
        setValue(watchedInput)
      }
    }
  }, [watchedInput])

  return (
    <>
      <Pane {...paneProps} position='relative' ref={clickOutsideRef}>
        <ControlledTextInput
          control={control}
          name={name}
          textInputProps={{
            id: 'address-field-street-input',
            placeholder: 'Søk etter adresse',
            autoComplete: 'new-password',
            onKeyDown: (e) => {
              if (e.key === 'Escape') {
                e.preventDefault()
                clearSuggestions()
              }
              if (e.key === 'Enter' || e.key === 'ArrowDown') {
                // Prevent form-submit and jump to first result if any
                e.preventDefault()
                if (hasSuggestions) {
                  if (resultsWrapperRef?.current) {
                    const resultItem = resultsWrapperRef.current as HTMLElement
                    if (resultItem) {
                      resultItem.focus()
                    }
                  }
                }
              }
            },
          }}
          wrapperProps={{
            label: (
              <Text size={300}>
                Gatenavn og nummer
                <Tooltip
                  content={
                    <Text fontSize={14} lineHeight='14px' color='white'>
                      I dette feltet kan du søke etter adressen din. Velg deretter fra listen for å fylle ut alle felter
                      automatisk.
                    </Text>
                  }
                >
                  <InfoSignIcon size={13} marginLeft={10} />
                </Tooltip>
              </Text>
            ),
            gridColumn: 'span 2',
          }}
          required
        />
        {hasSuggestions && (
          <Card
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
              if (e.key === 'Escape') {
                // Jump back to search input
                e.preventDefault()
                const streetInputElement = document.getElementById('address-field-street-input')
                if (streetInputElement) {
                  streetInputElement.focus()
                }
              }
            }}
            boxShadow='2px 2px 5px #BCC2BA'
            position='absolute'
            transform='translate(0, 100%)'
            bottom={20}
            background='white'
            zIndex={2}
          >
            <Menu>
              {data.map((suggestion, i) => {
                const {
                  place_id,
                  structured_formatting: { main_text, secondary_text },
                } = suggestion
                return (
                  <Menu.Item
                    {...((i === 0 && { ref: resultsWrapperRef }) || undefined)}
                    key={place_id}
                    onClick={(e) => handleSelect(e, suggestion)}
                    paddingY={30}
                  >
                    <Strong size={300}>{main_text}</Strong>, <Caption>{secondary_text}</Caption>
                  </Menu.Item>
                )
              })}
            </Menu>
          </Card>
        )}
      </Pane>
    </>
  )
}
