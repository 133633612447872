import { Button, Heading, HomeIcon, Pane, Paragraph } from 'evergreen-ui'
import * as React from 'react'
import { EnhancedDialog } from '/fiweb/components'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Link } from 'react-router-dom'

export const CampaignToolIsDiscontinued = () => {
  const { spacing } = useCustomTheme()
  return (
    <Pane>
      <EnhancedDialog isShown shouldCloseOnOverlayClick={false} shouldCloseOnEscapePress={false}>
        <Heading size={500}>Ikke lengre i bruk</Heading>
        <Pane display='flex' flexDirection='column' gap={spacing.xs}>
          <Paragraph>Lenken du forsøker å bruke peker til vår gamle veileder.</Paragraph>
          <Paragraph>Denne er ikke lengre i bruk.</Paragraph>
          <Paragraph>
            For mer informasjon, ta kontakt med vår kundeavdeling på{' '}
            <a href='mailto:kunde@folkeinvest.no'>kunde@folkeinvest.no</a>
          </Paragraph>
        </Pane>
        <Pane display='flex' width='100%' justifyContent='flex-end' paddingTop={spacing.m}>
          <Button is={Link} to='/' size='small' appearance='primary' iconBefore={HomeIcon}>
            Gå til forsiden
          </Button>
        </Pane>
      </EnhancedDialog>
    </Pane>
  )
}
