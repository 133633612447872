import * as React from 'react'
import { Dialog, DialogProps } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '../lib'

interface Props extends DialogProps {
  fullscreen?: boolean
  width?: string | number
}

export const EnhancedDialog = ({ children, fullscreen, width, ...dialogProps }: Props) => {
  const { spacing } = useCustomTheme()
  const { small } = useBreakpoint()
  const mouseDownTargetRef = React.useRef<MouseEvent['target']>()
  const [isSameMouseTarget, setIsSameMouseTarget] = React.useState(false)

  const isFullscreen = fullscreen ?? small

  const handlePop = () => {
    if (dialogProps.onCloseComplete) {
      dialogProps.onCloseComplete()
    }
  }

  React.useEffect(() => {
    if (dialogProps.isShown) {
      window.history.pushState({ isPopup: true }, '')
      window.addEventListener('popstate', handlePop)
      return () => {
        window.removeEventListener('popstate', handlePop)
      }
    }
  }, [dialogProps.isShown])

  return (
    <Dialog
      hasHeader={false}
      hasFooter={false}
      width={isFullscreen ? '100%' : width ?? 500}
      sideOffset={isFullscreen ? 0 : undefined}
      topOffset={isFullscreen ? 0 : '12vmin'}
      containerProps={isFullscreen ? { borderRadius: 0, height: '-webkit-fill-available' } : { borderRadius: '5px' }}
      shouldCloseOnOverlayClick={!!dialogProps?.shouldCloseOnOverlayClick || isSameMouseTarget}
      overlayProps={{
        onMouseDown: (e: MouseEvent) => {
          mouseDownTargetRef.current = e.target
        },
        onMouseUp: (e: MouseEvent) => {
          setIsSameMouseTarget(mouseDownTargetRef.current === e.target)
        },
      }}
      contentContainerProps={{
        padding: spacing.m,
        borderRadius: '0',
        height: '100%',
      }}
      {...dialogProps}
    >
      {children}
    </Dialog>
  )
}
