import React, { useState } from 'react'
import { formatGraphQLError } from '/~/utils/errorFormating'
import { CreateCommentMutationVariables, useCreateCommentMutation } from '/~/types/graphql'
import { Button, Heading, IconButton, Pane, Text, TextareaField, TextInputField, toaster, Tooltip } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Campaign, Comment } from './types'
import styled from 'styled-components'
import { useAuthContext } from '/~/utils/AuthContext'
import { gtmEventDiscussion } from '/~/utils/googleTagManager'
import { NotificationBell } from '/~/components'

const TextArea = styled(TextareaField)`
  && {
    font-size: 16px;
  }
`

interface Props {
  parent?: Comment
  campaign: Campaign
  onClose: () => void
}

const SubmitComment = ({ parent, onClose, campaign }: Props) => {
  const { user } = useAuthContext()
  const [createComment] = useCreateCommentMutation()
  const [subscribed, setSubscribed] = useState(!parent)
  const [titleInput, setTitleInput] = useState('')
  const [contentInput, setContentInput] = useState('')
  const theme = useCustomTheme()

  const handleClose = () => {
    setTitleInput('')
    setContentInput('')
    onClose()
  }

  const handleSubmit = async () => {
    const variables: Partial<CreateCommentMutationVariables['input']> = {
      content: contentInput,
    }

    if (parent) {
      variables.parent = parent._id
    } else if (!parent) {
      if (subscribed) {
        variables.subscribed = true
      }
      if (titleInput) {
        variables.title = titleInput
      }
    }
    gtmEventDiscussion(!parent)

    handleClose()
    try {
      await createComment({
        variables: { campaignId: campaign._id, input: variables },
      })

      toaster.success('Kommentar lagret')
    } catch (e) {
      const formatedError = formatGraphQLError(e)
      if (formatedError.includes('Diskusjonsforumet er låst')) {
        toaster.warning('Diskusjonsforumet er låst for nye kommentarer')
      } else {
        toaster.danger(`Noe gikk feil. ${formatedError}`)
      }
    }
  }

  if (!user?.bankidverified) {
    return null
  }

  const helperText = 'Vær oppmerksom at det er ikke mulighet til å endre kommentaren i etterkant'

  const titleLengthLimit = 40

  return (
    <Pane width='100%'>
      {!parent && (
        <div>
          <Heading is='h4'>Opprett ny diskusjon</Heading>

          <Pane display='flex' alignItems='center'>
            {campaign.user?._id !== user._id && (
              <Tooltip content='Epost-notifikasjon'>
                <IconButton
                  size='small'
                  appearance='minimal'
                  marginRight='12px'
                  onClick={() => setSubscribed(!subscribed)}
                  icon={<NotificationBell subscribed={subscribed} />}
                />
              </Tooltip>
            )}

            <TextInputField
              name='titleInput'
              label='Tittel/Tema'
              inputHeight={50}
              maxLength={titleLengthLimit}
              value={titleInput || ''}
              onChange={(e) => setTitleInput(e.target.value)}
            />

            <Text size={300} fontStyle='italic' marginLeft='5px'>
              <i>{`(${titleInput ? titleInput.length : '0'}/${titleLengthLimit} tegn)`}</i>
            </Text>
          </Pane>
        </div>
      )}

      <TextArea
        name='contentInput'
        label='Din kommentar'
        fontSize='26px'
        rows={2}
        value={contentInput || ''}
        onChange={(e) => setContentInput(e.target.value)}
      />
      <Text size={300}>
        <em>{helperText}</em>
      </Text>

      <Pane width='100%' display='flex' justifyContent='flex-end' marginTop={theme.spacing.xs}>
        <Button size='small' onClick={() => handleClose()}>
          Avbryt
        </Button>
        <Button
          size='small'
          appearance='primary'
          marginLeft='12px'
          disabled={!contentInput || contentInput?.trim().length < 3}
          onClick={handleSubmit}
        >
          Send
        </Button>
      </Pane>
    </Pane>
  )
}

export default SubmitComment
